import { MyShops, Transactions } from 'services/myShops/types'
import { CalculateProps, PromoTicketsProps } from './types'
import { CartItems, Coordinate } from 'services/cart/types'
import { firstLetterOfWordInUpperCase } from 'helpers/text'
import { TicketType } from 'types'

const sortTicketsByBuyDate = (tickets: MyShops[]) => {
  tickets.sort((a: MyShops, b: MyShops) => {
    let aDate = a.sales[0]?.date ? new Date(a.sales[0]?.date).getTime() : 0
    let bDate = b.sales[0]?.date ? new Date(b.sales[0]?.date).getTime() : 0
    return bDate - aDate
  })

  return tickets
}

const sortTransactionsByBuyDate = (transactions: Transactions[]) => {
  transactions.sort((a: Transactions, b: Transactions) => {
    let aDate = a.date ? new Date(a.date).getTime() : 0
    let bDate = b.date ? new Date(b.date).getTime() : 0
    return bDate - aDate
  })

  return transactions
}

const hashIdToNumber = (ticketHash: string) => {
  let retStr = ''
  try {
    for (let i = 0; i < ticketHash.length; i++) {
      let val = ticketHash.charCodeAt(i) - 48
      if (val < 10) retStr += '0' + val
      else retStr += val
    }
  } catch (ex) {
    retStr = ''
  }
  return retStr
}

const calculateTicketValueWithPromoTicket = ({ ticketValue, promoTicketQuantity }: PromoTicketsProps) => {
  const valueWithPromoTicket = ticketValue * promoTicketQuantity!

  return valueWithPromoTicket
}

const calculateTicketValue = ({ ticketValue, promoTicket, promoTicketQuantity }: CalculateProps) => {
  let value = ticketValue

  //Calculate ticket value with promo ticket
  if (!!promoTicket)
    value = calculateTicketValueWithPromoTicket({
      ticketValue,
      promoTicket,
      promoTicketQuantity,
    })

  return Number(value.toFixed(2))
}

const formatSeatBadgeNameWithoutTable = (value: string): string => {
  const parts = value.split('-')

  const seatNumber = parts.pop()
  const rowLetter = parts.pop()
  const sector = parts.join(' ')

  const formattedString = `${sector} - Fileira ${rowLetter} | Assento ${seatNumber}`

  return formattedString
}

const formatSeatBadgeNameWithTable = (coordinate: Coordinate, sectionName: string) => {
  const sectionNameRegex = new RegExp(sectionName, 'g')

  const aliasWithoutSectionName = coordinate?.alias!.replace(sectionNameRegex, '')
  const cleanAlias = aliasWithoutSectionName.replace(/^[\s\W_]+/, '').trim()

  return cleanAlias
}

const ticketNameInCart = (item: CartItems) => {

  const HAS_COORDINATES = item?.coordinates != undefined && item?.coordinates?.length > 0

  const CONTROLLED_PLACE_ALIAS = item?.coordinates?.[0]?.controlledPlaceAlias || ""
  const COORDINATES_ALIAS = item?.coordinates?.[0]?.alias 

  const COORDINATES_ROW = item?.coordinates?.[0]?.row
  const COORDINATES_TABLE_NUMBER = item?.coordinates?.[0]?.tableNumber

  const TICKET_ALIAS = `${CONTROLLED_PLACE_ALIAS} ${COORDINATES_ALIAS}`
  const TICKET_ROW = `- Fileira ${COORDINATES_ROW} ${CONTROLLED_PLACE_ALIAS} ${COORDINATES_TABLE_NUMBER}`
  const TICKET_ALIAS_OR_ROW = COORDINATES_ALIAS ? TICKET_ALIAS : TICKET_ROW
  const TICKET_CONTROLLED_NAME = HAS_COORDINATES ? TICKET_ALIAS_OR_ROW : ""
  const TICKET_SECTION_NAME = item?.sectionName
  const TICKET_NAME = item?.ticketName
  const TICKET_LOT_NUMBER = item?.lotNumber
  const TICKET_LOT = `Lote ${TICKET_LOT_NUMBER}`
  const TICKET_NAME_AND_LOT = ` | ${TICKET_NAME} | ${TICKET_LOT}`
  const TICKET_NAME_QUANTITY = `${item.quantity} Lugares`;

  const TICKET_NAME_FULL = item.bookAsAWhole 
    ? `${TICKET_CONTROLLED_NAME} - ${TICKET_NAME_QUANTITY} | ${TICKET_LOT}`
    : `${TICKET_SECTION_NAME} ${TICKET_CONTROLLED_NAME} ${TICKET_NAME_AND_LOT}`;

  const TICKET_NAME_FORMATED = firstLetterOfWordInUpperCase(TICKET_NAME_FULL)

  return TICKET_NAME_FORMATED;
}

/**
 * Identifies the type of ticket based on the provided cart item.
 * 
 * @param item - The cart item to identify the ticket type for.
 * @returns The type of ticket identified.
 */
function identifyTicketType (item: CartItems):TicketType{
  const HAS_COORDINATES = item?.coordinates != undefined && item?.coordinates?.length > 0
  const HAS_SEATS_LOCATION = item?.coordinates?.some((coordinate) => coordinate.SeatsLocation);
  const IS_BOOK_AS_A_WHOLE = item?.bookAsAWhole === true;
  const MAX = item?.coordinates?.[0]?.max
  const MIN = item?.coordinates?.[0]?.min
  const HAS_MIN_EQUAL_MAX = MAX === MIN && MAX !== undefined;

  if( HAS_COORDINATES && HAS_SEATS_LOCATION && IS_BOOK_AS_A_WHOLE ){
    return TicketType.table
  }

  if(HAS_COORDINATES && HAS_SEATS_LOCATION){
    return TicketType.seat
  }

  if(HAS_COORDINATES && HAS_MIN_EQUAL_MAX && MAX > 1){
    return TicketType.table
  }

  if(HAS_COORDINATES){
    return TicketType.seat
  }


  return TicketType.ticket
}

export {
  sortTicketsByBuyDate,
  sortTransactionsByBuyDate,
  hashIdToNumber,
  calculateTicketValue,
  formatSeatBadgeNameWithoutTable,
  formatSeatBadgeNameWithTable,
  ticketNameInCart,
  identifyTicketType
}
