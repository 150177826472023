import { CartProps } from 'services/cart/types'
import { GetEventInLocalStorage } from 'services/eventTicket/helper'
import { EventsTicketsData } from 'services/eventTicket/types'
import { setSessionIdLocalStorage } from 'services/sessionId/requests'
import { baseURL, getApiVersionForTickets } from '..'
import { AddCartProps, CartItems, CheckTicketByCpf, RemoveCartProps, SimpleCartItemProps, SimpleCartProps } from './types'
import { isCommissioner } from "helpers/comissioner";

export const generateCartItem = (event: EventsTicketsData, simpleCartItem: SimpleCartItemProps): CartItems => {
  const section = event?.sections.find(
    sectionitem => sectionitem.tickets.findIndex(ticketItem => ticketItem.lotId === simpleCartItem.LotId) !== -1,
  )
  const ticket = section?.tickets.find(ticketitem => ticketitem.lotId === simpleCartItem.LotId)
  const controlled = section?.controlledPlaces.find(
    controlleditem => controlleditem.controlledPlaceId === simpleCartItem.ControlledPlaceId,
  )

  if (section === undefined || ticket === undefined || (simpleCartItem.ControlledPlaceId && controlled === undefined)) {
    throw new Error('Erro ao gerar item do carrinho')
  }

  return {
    id: simpleCartItem.Id,
    quantity: simpleCartItem.Quantity,
    bookAsAWhole: section.bookAsAWhole,
    noInterest: event?.noInterest,
    slug: event?.slug,
    eventPlace: event?.location,
    eventHour: event?.time,
    docTypes: event?.docTypes,
    eventAddress: event?.address,
    eventAddressNumber: event?.addressNumber,
    eventCity: event?.city,
    eventState: event?.state,
    eventDate: event?.date,
    eventId: event?.id,
    eventName: event?.name,
    sectionAlias: section?.controlledPlaceAlias || '',
    sectionName: section?.name || '',
    sectionType: section?.type || 0,
    taxTotal: ticket?.taxOnline || 0,
    taxAppTotal: ticket?.taxOnlineApp || 0,
    ticketByCpf: ticket?.ticketByCpf || 0,
    ticketName: ticket?.name || '',
    promoTicketQuantity: ticket?.promoTicketQuantity || 0,
    lotId: ticket?.lotId || 0,
    lotNumber: ticket?.lotNumber || 0,
    franchiseId: ticket?.franchiseId || 0,
    value: ticket?.value || 0,
    promoTicket: ticket?.promoTicket || false,
    ticketId: ticket?.id || 0,
    individualTicketControl: ticket?.cpfLimitOptions == 'None' ? 'Não' : 'Sim',
    eventImageEvent: event?.eventImage,
    eventImageHomeEvent: event?.imageHomeEvent,
    eventImageTicket: event?.eventImageTicket,
    available: ticket?.available,
    LotProcessingFeeAdd: ticket?.processingFeeAdd,
    LotProcessingFeeValue: ticket?.processingFeeOnline,
    valueRefundServiceSite: ticket?.ValueRefundServiceSite,
    valueRefundServiceComissioner: ticket?.ValueRefundServiceComissioner,
    minQtd: section?.minQtd || 0,
    cpfs: [],
    coordinates:
      simpleCartItem.ControlledPlaceId && controlled !== undefined
        ? [
          {
            row: controlled.rowNumber,
            max: controlled.max,
            min: section.minQtd,
            available: controlled.Avaliable,
            status: controlled.status,
            alias: controlled.alias,
            controlledPlaceAlias: section.controlledPlaceAlias,
            SeatsLocation: controlled.SeatsLocation,
            SeatsTable: controlled.SeatsTable,
            tableNumber: controlled.placeNumber,
            controlledPlaceId: controlled.controlledPlaceId,
            quantity: simpleCartItem.Quantity,
          },
        ]
        : [],
  }
}

export const generateCart = (simpleCart: SimpleCartProps): CartProps => {
  const event = GetEventInLocalStorage()
  const cartItems = simpleCart.items.map(item => generateCartItem(event, item))
  const refundObject = !isCommissioner() ? event?.refundServiceSite : event?.refundServiceComissioner;

  const generatedCart = {
    id: simpleCart.id,
    exp: simpleCart.exp,
    holdToken: simpleCart.holdToken,
    franchiseId: event?.franchiseId,
    docType: event?.docTypes,
    individualControll: cartItems?.[0]?.individualTicketControl || 'Não',
    checkoutGateway: event?.checkoutGateway,
    showYellowButton: event?.showYellowButton,
    creditCardPaymentMethod: event?.creditCardPaymentMethod,
    pixPaymentMethod: event?.pixPaymentMethod,
    multiplePaymentMethod: event?.multiplePaymentMethod,
    useTicketLots: event?.useTicketLots,
    isNfeMandatory: event?.isNfeMandatory,
    items: cartItems,
    refund: refundObject
  }

  return generatedCart
}

export const getCart = async (sessionId: string): Promise<CartProps> => {
  try {
    const token: string = '56f869f6c051130a44cffbbc1c304525'
    const { data: response } = await baseURL.post('api/v3/getcart', {
      sessionId,
      token,
    })

    const generatedCart = generateCart(response?.cart)
    return generatedCart
  } catch (error: any) {
    throw new Error(error.response)
  }
}

export const postAddCart = async ({
  sessionId,
  lotId,
  quantity,
  controlledPlaceId,
  SocialEventId: eventId,
  holdToken
}: AddCartProps): Promise<CartProps> => {
  const currentVersion = getApiVersionForTickets()
  try {
    const { data: response } = await baseURL.post(`api/${currentVersion}/addtocart`, {
      sessionId,
      lotId,
      quantity,
      controlledPlaceId,
      SocialEventId: eventId,
      holdToken,
    })

    if (response?.status === 'error') {
      throw new Error(response?.message);
    }

    await setSessionIdLocalStorage(response?.sessionID)

    const generatedCart = currentVersion === 'v3' ? generateCart(response?.cart) : response?.cart
    return { ...generatedCart }
  } catch (error: any) {
    throw error.response
  }
}

export const postRemoveCart = async ({ sessionId, lotId, quantity, controlledPlaceId, holdToken }: RemoveCartProps): Promise<CartProps> => {
  try {
    const currentVersion = getApiVersionForTickets()
    const { data: response } = await baseURL.post(`api/${currentVersion}/removefromcart`, {
      sessionId,
      lotId,
      quantity,
      controlledPlaceId,
      holdToken
    })

    if (response?.status === 'error') {
      throw new Error(response?.message);
    }

    const generatedCart = currentVersion === 'v3' ? generateCart(response?.cart) : response?.cart
    return generatedCart
  } catch (error: any) {
    throw new Error(error?.response || error)
  }
}

export const checkRepeatedOnline = async (cpf: CheckTicketByCpf[]) => {
  try {
    const { data: response } = await baseURL.post('/api/CheckTicketsPerCPF', cpf)

    return response
  } catch (error: any) {
    throw error.response
  }
}
