import ExpirationCartTimer from 'components/ExpirationCartTimer'
import {
  ButtonWrapper,
  ContentWrapper,
  Description,
  DescriptionTotal,
  ExpirationWrapper,
  InfoWrapper,
  ItemData,
  ItemPlace,
  ItemQuantity,
  ItemQuantityWrapper,
  ItemTitle,
  ModalCloseButton,
  ModalHeader,
  ModalText,
  ModalTitle,
  Price,
  PriceWrapper,
  SubPriceWrapper,
  SubPriceWrapperTotal,
  TitleInfoWrapper,
  TitleWrapper,
  TotalWrapper,
  TriangleBallon,
  Wrapper,
} from './style'

import Modal from 'components/NewModal'
import { TransformToMoney } from 'helpers'
import { capitalizeFirstLetter } from 'helpers/text'
import { useCart } from 'hooks/useCart/useCart'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { closeModalImg, trashBlueImg } from 'assets/img'
import Button from 'components/NewButton'
import ExpiredCartError from '../../Errors/ExpiredCartError'
import { toast } from 'react-toastify'

const ModalPaymentDetail = () => {
  const { cart, total, totalTax, subTotal, totalProcessingFee, clearCart, removeFromCart, incompleteTables } = useCart()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const disableCartButton = incompleteTables.length > 0

  const formatDateEvent = (date: string) => {
    if (!date) return null

    const dateString = new Date(date)
    const week = new Intl.DateTimeFormat('pt-BR', { weekday: 'short' }).format(dateString).toLocaleUpperCase().replace('.', '')
    const day = new Intl.DateTimeFormat('pt-BR', { day: '2-digit' }).format(dateString).toLocaleUpperCase()
    const month = new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(dateString).toLocaleUpperCase()

    const weekFormatted = capitalizeFirstLetter(week)
    const monthFormatted = capitalizeFirstLetter(month)

    return `${weekFormatted}, ${day} de ${monthFormatted}`
  }

  const inCart = window.location.pathname == '/carrinho'

  const tables = cart?.items?.filter(item => item?.bookAsAWhole == true)
  const seats = cart?.items?.filter(item => item?.bookAsAWhole == false && item?.coordinates?.[0]?.SeatsLocation)
  const tickets = cart?.items?.filter(item => item?.bookAsAWhole == false && !item?.coordinates?.[0]?.SeatsLocation)

  const quantitytable = tables?.length
  const quantityseats = seats?.length
  const quantityticket = tickets?.reduce((ticketQuantity, cartItem) => {
    return ticketQuantity + cartItem.quantity
  }, 0)

  const textTable = quantitytable > 1 ? ' mesas' : ' mesa'
  const textSeats = quantityseats > 1 ? ' assentos' : ' assento'
  const textTicket = quantityticket > 1 ? ' ingressos' : ' ingresso'

  const handleClearCart = () => {
    try {
      cart?.items?.forEach(item => {
        removeFromCart({
          lotId: item?.lotId,
          quantity: item?.quantity,
          controlledPlaceId: item?.coordinates?.[0]?.controlledPlaceId,
          holdToken: cart?.holdToken,
        })
      })
    } catch (error: any) {
      if (error instanceof ExpiredCartError) {
        toast.error('Erro ao limpar o carrinho.')
        clearCart()
        navigate('/')
      }
    } finally {
      toast.success('Carrinho limpo com sucesso!')
    }
  }

  return (
    <Wrapper>
      <TriangleBallon />
      <TitleWrapper>Resumo da compra</TitleWrapper>

      <ExpirationWrapper>
        <ExpirationCartTimer size={18} />
      </ExpirationWrapper>

      <ContentWrapper>
        <TitleInfoWrapper>
          <ItemTitle>{cart?.items?.[0]?.eventName}</ItemTitle>
          <InfoWrapper>
            <ItemPlace>
              {cart?.items?.[0]?.eventCity}/{cart?.items?.[0]?.eventState}
            </ItemPlace>
            <ItemData>{formatDateEvent(cart?.items?.[0]?.eventDate)}</ItemData>
          </InfoWrapper>
        </TitleInfoWrapper>
        <ItemQuantityWrapper>
          {quantityticket > 0 ? (
            <ItemQuantity>
              {quantityticket} x{textTicket}
            </ItemQuantity>
          ) : (
            <></>
          )}
          {quantitytable > 0 ? (
            <ItemQuantity>
              {quantitytable} x{textTable}
            </ItemQuantity>
          ) : (
            <></>
          )}
          {quantityseats > 0 ? (
            <ItemQuantity>
              {quantityseats} x{textSeats}
            </ItemQuantity>
          ) : (
            <></>
          )}
        </ItemQuantityWrapper>
        <PriceWrapper>
          <SubPriceWrapper>
            <Description>Subtotal:</Description>
            <Price>{TransformToMoney('BRL', subTotal || 0)}</Price>
          </SubPriceWrapper>

          <SubPriceWrapper>
            <Description>Taxa de conveniência:</Description>
            <Price>{TransformToMoney('BRL', totalTax || 0)}</Price>
          </SubPriceWrapper>
        </PriceWrapper>
      </ContentWrapper>

      <TotalWrapper inCart>
        <DescriptionTotal>Total</DescriptionTotal>
        <SubPriceWrapperTotal>{TransformToMoney('BRL', Number(total - totalProcessingFee))}</SubPriceWrapperTotal>
      </TotalWrapper>

      {!inCart && (
        <ButtonWrapper>
          <Button height="40px" onClick={() => navigate('/carrinho')} disabled={disableCartButton}>
            Ir para o carrinho
          </Button>
        </ButtonWrapper>
      )}

      <ButtonWrapper>
        <Button height="40px" icon={trashBlueImg} outline={true} onClick={handleClearCart}>
          Limpar carrinho
        </Button>
      </ButtonWrapper>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <>
          <ModalHeader>
            <ModalTitle>Taxa de Processamento</ModalTitle>
            <ModalCloseButton src={closeModalImg} onClick={() => setIsModalOpen(false)} />
          </ModalHeader>

          <ModalText>
            A taxa de processamento representa o valor que será transferido ao serviço de transação online para tornar possível a
            realização do pagamento.
          </ModalText>
        </>
      </Modal>
    </Wrapper>
  )
}

export default ModalPaymentDetail
