import jwt from 'jwt-decode'
import React, { ReactNode, Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useRoutes } from 'react-router-dom'

import MiddlewareCityState from 'components/MiddlewareCityState'
import { ProtectedLayout } from '../components/ProtectedLayout'

const Home = React.lazy(() => import('../pages/Home'))
const UserAccount = React.lazy(() => import('../pages/UserAccount'))
const PrivacyPolicies = React.lazy(() => import('../pages/PrivacyPolicies'))
const Cart = React.lazy(() => import('../pages/Cart'))
const UserTickets = React.lazy(() => import('../pages/UserTickets'))
const UserData = React.lazy(() => import('../pages/UserData'))
const SignInRegister = React.lazy(() => import('../pages/SignInRegister'))
const MultiStepForm = React.lazy(() => import('../pages/SignInRegister/SignInRegisterMobile/RegisterMobile'))
const EventInfo = React.lazy(() => import('../pages/EventInfo'))
const EventMap = React.lazy(() => import('../pages/EventMap'))
const Checkout = React.lazy(() => import('../pages/Checkout'))
const TicketDetails = React.lazy(() => import('../pages/TicketDetails'))
const SearchEvents = React.lazy(() => import('../pages/SearchEvents'))
const Identification = React.lazy(() => import('../pages/Identification'))
const RecoveryPassword = React.lazy(() => import('../pages/RecoveryPassword'))
const TermsOfUse = React.lazy(() => import('../pages/TermsOfUse'))
const TermsOfConsent = React.lazy(() => import('../pages/TermsOfConsent'))
const ContactWhatsapp = React.lazy(() => import('../pages/ContactWhatsapp'))
const DireitosDosTitulares = React.lazy(() => import('../pages/DireitosDosTitulares'))
const RedirectOldEvent = React.lazy(() => import('../pages/RedirectOldEvent'))
const PrintTickets = React.lazy(() => import('../pages/PrintTickets'))
const HomeCommissioner = React.lazy(() => import('../pages/HomeCommissioner'))
const Maintenance = React.lazy(() => import('../pages/Maintenance'))
const LandingPage = React.lazy(() => import('../pages/LandingPage'))
const Compliance = React.lazy(() => import('../pages/Compliance'))
const HomeWebsite = React.lazy(() => import('../pages/HomeWebsite'))
const DownloadTerms = React.lazy(() => import('../pages/DownloadTerms'))
const HalfEntry = React.lazy(() => import('../pages/HalfEntry'))
const LegalDocuments = React.lazy(() => import('../pages/LegalDocuments'))
const RefundInfos = React.lazy(() => import('../pages/RefundInfos'))
const EventGroup = React.lazy(() => import('../pages/EventGroup'))
const ManausAccessibility = React.lazy(() => import('pages/ManausAccessibility'))

const AuthProvider = React.lazy(() => import('hooks/useAuthProvider'))
const AddressProvider = React.lazy(() => import('hooks/useAddress'))
const EventsTicketsProvider = React.lazy(() => import('hooks/useEventsTickets'))
const EventsDataProvider = React.lazy(() => import('hooks/useEventsData'))
const TicketsIdentificationProvider = React.lazy(() => import('hooks/useTicketsIdentification'))
const CommissionerProvider = React.lazy(() => import('hooks/useCommissioner'))
const CountriesProvider = React.lazy(() => import('hooks/useCountries'))
const EventsCommissionerProvider = React.lazy(() => import('hooks/useEventsCommissioner'))
const PaymentProvider = React.lazy(() => import('hooks/useNFCEDocument'))
const CartProvider = React.lazy(() => import('hooks/useCart'))
const CheckoutProvider = React.lazy(() => import('hooks/useCheckout'))
const TicketsInfoProvider = React.lazy(() => import('hooks/useTicketsInfo'));

import { isCommissioner } from "helpers/comissioner";
import { getUserLocalStorage } from "hooks/useAuthProvider/util";
import { Token } from "hooks/useAuthProvider/types";
import { RefundableOptions, RefundableProvider } from "protect-group-dynamic-refundable-react";
import { useCart } from "hooks/useCart/useCart";
import { GetEventInLocalStorage } from "services/eventTicket/helper";

import { ModalPendingCheckout } from 'components/ModalPendingCheckout'
import WhatsAppScriptForMobile from "../pages/WhatsAppScriptForMobile"

type AddCommissionerProviderProps = {
  children: ReactNode
}

const AddCommissionerProvider = ({ children }: AddCommissionerProviderProps) => {
  return isCommissioner() ? (
    <CommissionerProvider>
      <EventsCommissionerProvider>{children}</EventsCommissionerProvider>
    </CommissionerProvider>
  ) : (
    <>{children}</>
  )
}

const OnlyValidCart = ({ children }: AddCommissionerProviderProps) => {
  const { incompleteTables } = useCart()
  const navigate = useNavigate()

  useEffect(() => {
    if (incompleteTables?.length > 0) {
      const event = GetEventInLocalStorage()
      navigate(`/eventmap/${event.slug}`)
    }
  }, [incompleteTables])

  return <>{children}</>
}

const OnlyLoggedAndVerified = ({ children }: AddCommissionerProviderProps) => {
  const user = getUserLocalStorage()
  try {
    const obj: Token = jwt(user?.token)
    if (!obj) {
      window.location.href = '/login'
    } else if (obj?.user?.emailVerified !== 1) {
      window.location.href = '/'
    }
  } catch (e) {
    window.location.href = '/'
  }

  return <>{children}</>
}

const css = ` 
  #adopt-controller-button{
    display: none !important;
  }
`

const Carregando = () => <div>Carregando...</div>

export function MainRoutes() {
  useEffect(() => window.scrollTo(0, 0))

  const options: RefundableOptions = {
    containerSize: 'small',
    environment:
      process.env.REACT_APP_BASE_URL!.includes('stg') || process.env.REACT_APP_BASE_URL!.includes('localhost') ? 'test' : 'prod',
    currencyCode: 'BRL',
    languageCode: 'pt',
    vendorCode: process.env.REACT_APP_PROTECT_GROUP_MEMBER_ID!,
    useSaleAction: false,
  }

  const routes = useRoutes(
    process.env.REACT_APP_MAINTENANCE !== 'TRUE'
      ? [
          {
            path: '/',
            element: !isCommissioner() ? (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <CheckoutProvider>
                          <Helmet>
                            <title>Home | Q2 Ingressos</title>
                          </Helmet>
                          <Home />
                          <ModalPendingCheckout />
                      </CheckoutProvider>
                    </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ) : (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <EventsDataProvider>
                    <CartProvider>
                      <CheckoutProvider>
                        <HomeCommissioner />
                        <ModalPendingCheckout />
                      </CheckoutProvider>
                    </CartProvider>
                  </EventsDataProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/eventmap/:slug',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <EventsTicketsProvider>
                    <CartProvider>
                      <CheckoutProvider>
                        <EventsDataProvider>
                          <Helmet>
                            <title>Q2 Ingressos</title>
                            <style type="text/css">{css}</style>
                          </Helmet>
                          <EventMap />
                          <ModalPendingCheckout />
                        </EventsDataProvider>
                      </CheckoutProvider>
                    </CartProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/events/:slug',
            element: (
              <Suspense fallback={<Carregando />}>
                <TicketsInfoProvider>
                  <AddCommissionerProvider>
                    <EventsTicketsProvider>
                      <CartProvider>
                        <CheckoutProvider>
                          <EventsDataProvider>
                            <Helmet>
                              <title>Q2 Ingressos</title>
                            </Helmet>
                            <EventInfo />
                            <ModalPendingCheckout />
                          </EventsDataProvider>
                        </CheckoutProvider>
                      </CartProvider>
                    </EventsTicketsProvider>
                  </AddCommissionerProvider>
                </TicketsInfoProvider>
              </Suspense>
            ),
          },
          {
            path: '/eventgroup/:slug',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <EventsTicketsProvider>
                    <CartProvider>
                      <CheckoutProvider>
                        <EventsDataProvider>
                          <Helmet>
                            <title>Q2 Ingressos</title>
                          </Helmet>
                          <EventGroup />
                          <ModalPendingCheckout />
                        </EventsDataProvider>
                      </CheckoutProvider>
                    </CartProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/:slug',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <CheckoutProvider>
                            <Helmet>
                              <title>Q2 Ingressos</title>
                            </Helmet>
                            <EventInfo />
                            <ModalPendingCheckout />
                        </CheckoutProvider>
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/meus-ingressos',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AuthProvider>
                    <Helmet>
                      <title>Meus ingressos | Q2 Ingressos</title>
                    </Helmet>
                    <EventsTicketsProvider>
                      <EventsDataProvider>
                        <CartProvider>
                          <CheckoutProvider>
                              <ProtectedLayout>
                                <UserTickets />
                              </ProtectedLayout>
                              <ModalPendingCheckout />
                          </CheckoutProvider>
                        </CartProvider>
                      </EventsDataProvider>
                    </EventsTicketsProvider>
                  </AuthProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/Home/MyShops',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AuthProvider>
                    <Helmet>
                      <title>Meus ingressos | Q2 Ingressos</title>
                    </Helmet>
                    <EventsTicketsProvider>
                      <EventsDataProvider>
                        <CartProvider>
                          <CheckoutProvider>
                            <ProtectedLayout>
                              <UserTickets />
                            </ProtectedLayout>
                            <ModalPendingCheckout />
                          </CheckoutProvider>
                        </CartProvider>
                      </EventsDataProvider>
                    </EventsTicketsProvider>
                  </AuthProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/minha-conta',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AuthProvider>
                    <Helmet>
                      <title>Minha conta | Q2 Ingressos</title>
                    </Helmet>
                    <EventsTicketsProvider>
                      <EventsDataProvider>
                        <CartProvider>
                          <CheckoutProvider>
                            <ProtectedLayout>
                              <UserAccount />
                            </ProtectedLayout>
                            <ModalPendingCheckout />
                          </CheckoutProvider>
                        </CartProvider>
                      </EventsDataProvider>
                    </EventsTicketsProvider>
                  </AuthProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/dados-da-conta',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <CountriesProvider>
                    <AuthProvider>
                      <Helmet>
                        <title>Dados da conta | Q2 Ingressos</title>
                      </Helmet>
                      <EventsTicketsProvider>
                        <EventsDataProvider>
                          <CartProvider>
                            <AddressProvider>
                              <CheckoutProvider>
                                <ProtectedLayout>
                                  <UserData />
                                </ProtectedLayout>
                                <ModalPendingCheckout />
                              </CheckoutProvider>
                            </AddressProvider>
                          </CartProvider>
                        </EventsDataProvider>
                      </EventsTicketsProvider>
                    </AuthProvider>
                  </CountriesProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/pagamento',
            element: (
              <Suspense fallback={<Carregando />}>
                <OnlyLoggedAndVerified>
                  <AddressProvider>
                    <AddCommissionerProvider>
                      <PaymentProvider>
                        <AuthProvider>
                          <Helmet>
                            <title>Pagamento | Q2 Ingressos</title>
                          </Helmet>
                          <EventsTicketsProvider>
                            <EventsDataProvider>
                              <CartProvider>
                                <OnlyValidCart>
                                  <RefundableProvider options={options}>
                                    <TicketsIdentificationProvider>
                                      <ProtectedLayout>
                                        <MiddlewareCityState>
                                          <CheckoutProvider>
                                            <Checkout />
                                          </CheckoutProvider>
                                        </MiddlewareCityState>
                                      </ProtectedLayout>
                                    </TicketsIdentificationProvider>
                                  </RefundableProvider>
                                </OnlyValidCart>
                              </CartProvider>
                            </EventsDataProvider>
                          </EventsTicketsProvider>
                        </AuthProvider>
                      </PaymentProvider>
                    </AddCommissionerProvider>
                  </AddressProvider>
                </OnlyLoggedAndVerified>
              </Suspense>
            ),
          },
          {
            path: '/identificacao',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AuthProvider>
                    <Helmet>
                      <title>Identificação | Q2 Ingressos</title>
                    </Helmet>
                    <EventsTicketsProvider>
                      <EventsDataProvider>
                        <CartProvider>
                          <TicketsIdentificationProvider>
                            <ProtectedLayout>
                              <Identification />
                            </ProtectedLayout>
                          </TicketsIdentificationProvider>
                        </CartProvider>
                      </EventsDataProvider>
                    </EventsTicketsProvider>
                  </AuthProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/detalhes-do-ingresso/:eventId',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AuthProvider>
                    <Helmet>
                      <title>Detalhes do ingresso | Q2 Ingressos</title>
                    </Helmet>
                    <EventsTicketsProvider>
                      <EventsDataProvider>
                        <CartProvider>
                          <ProtectedLayout>
                            <TicketDetails />
                          </ProtectedLayout>
                        </CartProvider>
                      </EventsDataProvider>
                    </EventsTicketsProvider>
                  </AuthProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/login',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AddressProvider>
                    <CountriesProvider>
                      <EventsTicketsProvider>
                        <Helmet>
                          <title>Login | Q2 Ingressos</title>
                        </Helmet>
                        <EventsDataProvider>
                          <CartProvider>
                            <SignInRegister />
                          </CartProvider>
                        </EventsDataProvider>
                      </EventsTicketsProvider>
                    </CountriesProvider>
                  </AddressProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/acessibilidade-pcd',
            element: (
              <Suspense fallback={<Carregando />}>
                <Helmet>
                  <title>Acessibilidade Manaus | Q2 Ingressos</title>
                </Helmet>
                  <EventsDataProvider>
                    <ManausAccessibility /> 
                  </EventsDataProvider>
              </Suspense>
            ),
          },
          {
            path: '/cadastrar',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <AddressProvider>
                    <CountriesProvider>
                      <EventsTicketsProvider>
                        <Helmet>
                          <title>Cadastrar | Q2 Ingressos</title>
                        </Helmet>
                        <EventsDataProvider>
                          <CartProvider>
                            <SignInRegister />
                          </CartProvider>
                        </EventsDataProvider>
                      </EventsTicketsProvider>
                    </CountriesProvider>
                  </AddressProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/resultados',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Pesquisa | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <CheckoutProvider>
                          <SearchEvents />
                          <ModalPendingCheckout />
                        </CheckoutProvider>
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/cadastrar-mobile',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <CountriesProvider>
                    <AddressProvider>
                      <Helmet>
                        <title>Cadastrar | Q2 Ingressos</title>
                      </Helmet>
                      <EventsTicketsProvider>
                        <EventsDataProvider>
                          <CartProvider>
                            <MultiStepForm />
                          </CartProvider>
                        </EventsDataProvider>
                      </EventsTicketsProvider>
                    </AddressProvider>
                  </CountriesProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/carrinho',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <EventsTicketsProvider>
                    <TicketsIdentificationProvider>
                      <EventsDataProvider>
                        <CartProvider>
                          <CheckoutProvider>
                            <OnlyValidCart>
                              <Helmet>
                                <title>Carrinho | Q2 Ingressos</title>
                              </Helmet>
                              <Cart />
                              <ModalPendingCheckout />
                            </OnlyValidCart>
                          </CheckoutProvider>
                        </CartProvider>
                      </EventsDataProvider>
                    </TicketsIdentificationProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/direitos-dos-titulares',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Direitos dos titulares | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <DireitosDosTitulares />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/reembolso-estendido',
            element: (
              <Suspense fallback={<Carregando />}>
                <Helmet>
                  <title>Ingresso Reembolsável</title>
                </Helmet>
                <EventsDataProvider>
                  <RefundInfos />
                </EventsDataProvider>
              </Suspense>
            ),
          },
          {
            path: '/Home/Privacy',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Politicas de privacidade | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <PrivacyPolicies />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/politicas-de-privacidade',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Politicas de privacidade | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <PrivacyPolicies />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/home/confirmpasswordreset',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Recuperar senha | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <RecoveryPassword />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/home/PrintTickets',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <PrintTickets />
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/compliance',
            element: (
              <Suspense fallback={<Carregando />}>
                <Compliance />
              </Suspense>
            ),
          },
          {
            path: '/Home/TermsOfUse',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Termos de uso | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <TermsOfUse />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/termos-de-uso',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Termos de uso | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <TermsOfUse />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/Home/WebsiteTickets',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <RedirectOldEvent />
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/Home/Website/:Id',
            element: (
              <Suspense fallback={<Carregando />}>
                <HomeWebsite />
              </Suspense>
            ),
          },
          {
            path: '/whatsapp',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Whatsapp | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <ContactWhatsapp />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/whatsapp-mobile',
            element: (
              <Suspense fallback={<Carregando />}>
                <WhatsAppScriptForMobile />
              </Suspense>
            ),
          },
          {
            path: '/termos-de-consentimento-para-produtores',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Termos de consentimento | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <TermsOfConsent />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/institucional',
            element: (
              <Suspense fallback={<Carregando />}>
                <LandingPage />
              </Suspense>
            ),
          },
          {
            path: '/meia-entrada',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Informações sobre meia entrada | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <HalfEntry />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/documentos-legais',
            element: (
              <Suspense fallback={<Carregando />}>
                <AddCommissionerProvider>
                  <Helmet>
                    <title>Documentos legais | Q2 Ingressos</title>
                  </Helmet>
                  <EventsTicketsProvider>
                    <EventsDataProvider>
                      <CartProvider>
                        <LegalDocuments />
                      </CartProvider>
                    </EventsDataProvider>
                  </EventsTicketsProvider>
                </AddCommissionerProvider>
              </Suspense>
            ),
          },
          {
            path: '/termodecessao',
            element: (
              <Suspense fallback={<Carregando />}>
                <DownloadTerms />
              </Suspense>
            ),
          },
          {
            path: '/mpexport',
            element: (
              <Helmet>
                <script src="https://sdk.mercadopago.com/js/v2"></script>
              </Helmet>
            ),
          },
        ]
      : [
          {
            path: '*',
            element: (
              <Suspense fallback={<Carregando />}>
                <Maintenance />
              </Suspense>
            ),
          },
        ],
  )

  return routes
}
